html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Monrope', sans-serif;
  color: white;
}
